import styles from './_featuredHomepageBlock.module.scss'
import React, { useContext, useRef } from 'react'
import { ModalsContext } from '../utils/modals'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper/core'
import Image from 'next/legacy/image'
import { FeaturedHomepageBlock } from '../__generated__/graphql'

SwiperCore.use([Autoplay, Pagination])

const FeaturedHomepageBlock: React.FC<{ data: FeaturedHomepageBlock[] }> = ({
  data
}) => {
  if (!data.length) return <></>

  const { openModal } = useContext(ModalsContext)

  const slides = data.map((block) => {
    const linkRef = useRef<HTMLAnchorElement>()

    const clickHandler = (e) => {
      if (e.currentTarget.href.includes('wistia:') || !e.currentTarget.href) {
        const link = e.currentTarget.href.includes('wistia:')
          ? e.currentTarget.href
          : null
        const wistiaVideoKey = link !== null ? link.split(':')[1] : null
        e.preventDefault()
        openSecretMessageModal(wistiaVideoKey)
      }
    }

    const openSecretMessageModal = (wistiaVideoKey) => {
      openModal({
        name: 'SecretMessage',
        props: { wistiaVideoKey: wistiaVideoKey },
        modalTitle: block.modalTitle
      })
    }

    const bgImageStyle = {
      opacity: block.opacity
    }
    return (
      <SwiperSlide key={block.id}>
        <section className={styles.featuredHomepageBlock}>
          <div className={styles.featuredHomepageBlockContainer}>
            <div className={styles.imageContainer} style={bgImageStyle}>
              <Image
                priority={true}
                src={block.backgroundImage}
                layout="fill"
                objectFit="cover"
                sizes="(max-width: 256px) 256px,
                (max-width: 384px) 384px,
                (max-width: 769px) 769px,
                1408px"
                loading="eager"
              />
            </div>
            <div className={styles.subTitle + ' h4'}>{block.header}</div>
            <h1>{block.subheader}</h1>
            <a
              ref={linkRef}
              className="button is-primary is-large"
              target="_blank"
              rel="noreferrer"
              href={block.url}
              onClick={clickHandler}
            >
              {block.buttonText}
            </a>
          </div>
        </section>
      </SwiperSlide>
    )
  })

  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      className={styles.swiper}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return data.length > 1
            ? '<span class="' + className + '"></span>'
            : '<span class="swiper-pagination-disabled"></span>'
        }
      }}
      loop={data.length > 1}
    >
      {slides}
    </Swiper>
  )
}

export default React.memo(FeaturedHomepageBlock)
